import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import Button from 'ui-kit/button/button';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';
import PlanBadge from 'ui-kit/icons/plan-badge/plan-badge';

import { AutoRefillToggle } from 'components/auto-refill-toggle';
import { PrescriptionDetails } from 'components/prescription-details';
import { PrescriptionStatusBox } from 'components/prescription-status-box';
import { PrescriptionStatuses } from 'components/prescription-statuses';

import { drugSelector } from 'state/drug/drug.selectors';
import { easyRefillFamilyPlansMapSelector, easyRefillPlanAliasSelector } from 'state/easy-refill/easy-refill.selectors';

import { RxResult } from 'types/auto-refill';

import { noop } from 'util/function';
import {
    addDays,
    isRxCloseToExpire,
    isRxExpired,
    isRxExpiresToday,
    isRxWhiteCard,
    isShipStatusExpired
} from 'util/prescription';

import './easy-refill-prescription-card-mobile.style.scss';
import { findPriceMatch } from './easy-refill-prescriptions-card.component';
import PrescriptionCardIcon from './prescription-card.icon';
import { EasyRefillPrescriptionCardProps } from './types';

function showDebuginfo(card: RxResult) {
    /* eslint-disable no-console */
    console.group(`${card.dispensedProductName}`);
    console.log('Prescription Card: ' + card.dispensedProductName);
    console.log('rxStatus: ' + card.rxStatus);
    console.log('itemInWorkflow: ' + card.itemInWorkflow);
    console.log('orderLineQueueStatus: ' + card.orderLineQueueStatus);
    console.log('nextFillDate: ' + card.nextFillDate);
    console.log('lastFillDate: ' + card.lastFillDate);
    console.log('shipStatusExpirationDate: ' + addDays(card.lastFillDate, 14).toLocaleDateString('en-US'));
    console.log('isShipStatusExpired: ' + isShipStatusExpired(card.lastFillDate));
    console.log(card);
    console.groupEnd();
    /* eslint-enable no-console */
}

export default function EasyRefillPrescriptionCardV2Mobile({
    fullPayload,
    prescriptionName,
    orderStatus,
    refillsLeft,
    rxNumber,
    details = [],
    statuses = [],
    ctas = [],
    autoRefillBusy,
    autorefillChange,
    autoRefillEnabledForRx,
    autoRefillOnChange,
    autoRefillEligibleForRefill,
    isAccountInsured,
    className,
    isCaliforniaUser,
    isRxBusy,
    isBirdiSelect,
    zipCode
}: EasyRefillPrescriptionCardProps) {
    const { t } = useTranslation();
    const nodeRef = useRef(null);
    let detailsWithPrice = details;

    const rxDisplayStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'RX';
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatus]);

    const rxNextRefillStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'NEXT_REFILL';
        });
    }, [statuses]);

    const orderStatusClassName = orderStatus.toLowerCase().replace(/ /g, '_');
    const classes = classNames('prescription-card-v2 easy-refill mobile', orderStatusClassName, className);

    const nexRefillContainerClasses = classNames('prescription-card__rx-refills-container', {
        'prescription-card__refill-due': rxNextRefillStatuses[0]?.isRefillDue
    });
    const prescriptionCardRefillsOrderClasses = classNames('prescription-card__refills-order', {
        'prescription-card__refill-due': rxNextRefillStatuses[0]?.isRefillDue
    });

    const { drugDiscountPrices } = useSelector(drugSelector);
    const price = findPriceMatch(rxNumber, zipCode, drugDiscountPrices);

    const isPriceVisible = useMemo(() => {
        const planAlias = drugDiscountPrices.find((item) => item.rxNumber === rxNumber)?.planAlias;
        return (!isAccountInsured || planAlias === 'BRD02') && !!price && price !== 'NA' && Number(price) !== 0;
    }, [drugDiscountPrices, isAccountInsured, price, rxNumber]);

    if (isPriceVisible) {
        detailsWithPrice = [
            ...details,
            {
                detail: (
                    <>
                        {t('components.prescriptionCard.cashPrice')}: <strong>${price}</strong>
                    </>
                )
            }
        ];
    }

    /**
     * The Birdie Select Badge requires specific validation based on the user's plan to ensure it
     * is displayed correctly according to the prescription response, not discount prices.
     * Additional logic needs to be implemented for this purpose.
     */

    const userPlanAlias: string = useSelector(easyRefillPlanAliasSelector);
    const familyPlansMap: Record<string, string> = useSelector(easyRefillFamilyPlansMapSelector);
    const epostPatientNum: string = fullPayload?.epostPatientNum || '';

    const isBirdiSelectVisible = useMemo(() => {
        const isMembershipPlanAlias =
            Object.keys(familyPlansMap).length > 0
                ? familyPlansMap[epostPatientNum] === 'BRD02'
                : userPlanAlias === 'BRD02';

        //TODO: Here is needed an extra valdiation to show the badge just for Care & Care + users.
        return isMembershipPlanAlias && isBirdiSelect;
    }, [familyPlansMap, epostPatientNum, userPlanAlias, isBirdiSelect]);

    return (
        <div className={classes}>
            {/* Order Status */}
            <PrescriptionStatusBox statuses={rxDisplayStatuses} />

            {/* Content Section */}
            <div className="prescription-card__head-container">
                {/* Icon Section */}
                <div className="prescription-card__icon_container">
                    <CSSTransition nodeRef={nodeRef} in={!!''} timeout={4000} classNames="prescription-card-icon">
                        <div
                            ref={nodeRef}
                            className={'prescription-card__icon-badge-new'}
                            role="button"
                            tabIndex={0}
                            onKeyDown={noop}
                            onClick={() => {
                                showDebuginfo(fullPayload);
                            }}
                        >
                            <PrescriptionCardIcon variant={orderStatus} orderSubStatus={''} t={t} />
                        </div>
                    </CSSTransition>
                </div>

                {/* Prescription Name */}
                <h4 className="prescription-card__title" title={prescriptionName?.toUpperCase()}>
                    <span>{prescriptionName}</span>
                    {/* Plan Badge */}
                    {isBirdiSelectVisible && <PlanBadge variant="birdi-select-blue" />}
                </h4>
            </div>

            {/* RX Number & Details */}
            <div className="prescription-card__rx-details-container">
                <PrescriptionDetails details={detailsWithPrice} expanded={true} />
            </div>

            {/* Refill & Order Statuses Mobile */}
            <div className={prescriptionCardRefillsOrderClasses}>
                {/* Refill Status */}
                {!isRxWhiteCard(orderStatus) && (
                    <div
                        className={classNames('prescription-card__refills', {
                            'prescription-card__refills--empty': refillsLeft === 0
                        })}
                    >
                        <div className="prescription-card__refills-label">
                            {refillsLeft > 0 && <span>{t('components.prescriptionCard.orderRefillAva')}:</span>}
                        </div>

                        <div>
                            <span className={refillsLeft === 1 ? 'text-warning' : ''}>
                                {refillsLeft} {t('components.prescriptionCard.refillsLeftTitle')}
                            </span>
                        </div>
                    </div>
                )}

                {/* Next Refill */}
                <div className={nexRefillContainerClasses}>
                    <div>
                        {rxNextRefillStatuses.length > 0 && (
                            <div className="prescription-card__next-refill">
                                <div className="prescription-card__details-status-headings">
                                    {rxNextRefillStatuses[0]?.isRefillDue ? (
                                        <>
                                            <div className="icon">
                                                <CircleInfo />
                                            </div>
                                            {t(`components.prescriptionCard.orderRefillDue`)}
                                        </>
                                    ) : (
                                        t(`components.prescriptionCard.orderNextRefill`)
                                    )}
                                </div>
                                <PrescriptionStatuses statuses={rxNextRefillStatuses} />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {!!fullPayload.lastFillDate && (orderStatus === 'EXPIRED' || orderStatus === 'OUT_OF_REFILLS') && (
                <div className="prescription-card__rx-refills-container">
                    <div className="prescription-card__next-refill">
                        <div className="prescription-card__details-status-headings">
                            {t('components.prescriptionCard.orderLastFilled')}{' '}
                            <strong>{fullPayload.lastFillDate}</strong>
                        </div>
                    </div>
                </div>
            )}

            {/* Auto Refill */}
            <div className="prescription-card__auto-refill-toggle">
                {autoRefillEligibleForRefill && (
                    <AutoRefillToggle
                        onChange={(val: boolean, isRenew: boolean) =>
                            autoRefillOnChange && autoRefillOnChange([fullPayload], val, isRenew)
                        }
                        isBusy={autoRefillBusy ?? false}
                        t={t}
                        rxEligible={autoRefillEligibleForRefill ?? false}
                        checked={autoRefillEnabledForRx ?? false}
                        isRxExpiresSoon={isRxCloseToExpire(fullPayload.consentExpiration)}
                        isRxExpired={isRxExpired(fullPayload.consentExpiration)}
                        isConsentExpiresSoon={
                            isRxCloseToExpire(moment(fullPayload.consentExpiration).format('MM/DD/YYYY')) ||
                            isRxExpiresToday(moment(fullPayload.consentExpiration).format('MM/DD/YYYY'))
                        }
                        isConsentExpired={isRxExpired(moment(fullPayload.consentExpiration).format('MM/DD/YYYY'))}
                        isCaliforniaUser={isCaliforniaUser}
                        isDisabled={autorefillChange}
                    />
                )}
            </div>

            {/* Cart */}
            <div className="prescription-card__cart_container flex-column align-items-center justify-content-start">
                {ctas?.map((cta, idx) => (
                    <Button
                        className="sm-full mb-0"
                        key={`prescription-card-cta-button-${idx}`}
                        {...cta}
                        type={'button'}
                        onClick={() => {
                            cta.onClick();
                        }}
                        async
                        isBusy={isRxBusy}
                        disabled={isRxBusy || cta.disabled}
                        dataGALocation="MedicineCabinet"
                        variant={cta.disabled ? 'outline' : 'outline-primary'}
                    />
                ))}
            </div>
        </div>
    );
}
