// General
import { graphql, navigate } from 'gatsby';
import { ALLOW_INSURED_BIRDI_PRICE, ENABLE_AUTO_REFILL } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// Ui-kit
import NoRefillsIcon from 'ui-kit/illustrations/no-refills/no-refills';
import PageSection from 'ui-kit/page-section/page-section';
import Spinner from 'ui-kit/spinner/spinner';

// Display components
import EmptyState from 'display-components/empty-state/empty-state';

//Components
import AutoRefillBanner from 'components/auto-refill-banner/auto-refill-banner.component';
import { AutoRefillTermsAndConditionsModal } from 'components/auto-refill-terms-and-conditions-modal';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import InternalHeader from 'components/internal-header/internal-header.component';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';
import PrescriptionTabs from 'components/prescriptions-list-v2/prescription-tabs/prescription-tabs.component';
import { easyRefillPrescriptionPayloadToProps } from 'components/prescriptions-list-v2/prescriptions-card/easy-refill-payload-to-props';
import EasyRefillPrescriptionCardV2Mobile from 'components/prescriptions-list-v2/prescriptions-card/easy-refill-prescriptions-card-mobile.component';
import EasyRefillPrescriptionCardV2Desktop from 'components/prescriptions-list-v2/prescriptions-card/easy-refill-prescriptions-card.component';
import RefillDetailsCart from 'components/refill-details-cart/refill-details-cart';

// States - Reducers
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
// States - Routines
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
// States - Selectors
import { drugSelector } from 'state/drug/drug.selectors';
import {
    easyRefillFetchHealthConditionsRoutine,
    easyRefillGetCartRoutine,
    easyRefillGetPatientAddressesRoutine,
    easyRefillGetPatientDataRoutine,
    easyRefillGetPatientPaymentCardsRoutine,
    easyRefillGetPatientPlansRoutine,
    easyRefillStartOrderRoutine,
    easyRefillToggleAutoRefillRxStatusRoutine
} from 'state/easy-refill/easy-refill.routines';
import {
    autoRefillFlagsSelector,
    autoRefillToggleBusySelector,
    cartSelector,
    easyRefillAcCodeSelector,
    easyRefillAccountHasInsuranceSelector,
    easyRefillAccountIsCaliforniaUserSelector,
    easyRefillAddressesSelector,
    easyRefillCartItemsSelector,
    easyRefillEligibleRxsCaregiverSelector,
    easyRefillEligibleRxsSelector,
    easyRefillEpostPatientNumSelector,
    easyRefillExpiredSelector,
    easyRefillFamilyDependentsSelector,
    easyRefillFamilyRxSelector,
    easyRefillFirstNameSelector,
    easyRefillIsCaregiverSelector,
    easyRefillLoadingCartSelector,
    easyRefillLoadingRxsSelector,
    easyRefillOrderAddressSelector,
    easyRefillPatientAddressSelector,
    easyRefillPaymentCardsSelector,
    easyRefillPlansSelector,
    easyRefillRemoveCartBtnDisabled,
    easyRefillRxsLoadedSelector,
    easyRefillRxsSelector,
    easyRefillRxsToRefillSelector,
    easyRefillSelector,
    easyRefillToggleBusySelector,
    easyRefillUserBearerTokenSelector
} from 'state/easy-refill/easy-refill.selectors';

// Interfaces and Types
import { AutoRefillFlag } from 'types/auto-refill';
import { CreateOrUpdateResponse, EasyRefillPatientDataCaregiver, EasyRefillRxResult } from 'types/easy-refill';

// Util
import { validateAutoRefillTermsAndConditionsModalDisplay } from 'util/autorefill';
import { lowercaseAndCapitalize } from 'util/cart';
import { mapEasyRefillFamilyMembers, processEasyRefillCart } from 'util/easy-refill';
import { getPhoneNumber } from 'util/globalVariables';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';
import storageHelper from 'util/storageHelper';
import { capitalizeFirstLetter } from 'util/string';

import { useEasyRefillHealthConditions } from 'hooks/useEasyRefillHealthConditions';

// HOC
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';

import { EasyRefillErrorModal } from '..';
// Styles
import './index.style.scss';

// Main component
const EasyRefillPrescriptions = ({ data }: any) => {
    // General
    const { blueSkyBackground } = data;

    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // States
    const [orderedEasyRefillRxs, setOrderedEasyRefillRxs] = useState<EasyRefillRxResult[]>([]);
    const [activeDependentTab, setActiveDependentTab] = useState<string | null>(null);
    const [isRxBusy, setIsRxBusy] = useState<boolean>(false);

    // Selectors
    const firstName = useSelector(easyRefillFirstNameSelector);
    const easyRefillLoadingRxs = useSelector(easyRefillLoadingRxsSelector);
    const easyRefillBearerToken = useSelector(easyRefillUserBearerTokenSelector);
    const easyRefillRxsLoaded = useSelector(easyRefillRxsLoadedSelector);
    const easyRefillRxs = useSelector(easyRefillRxsSelector);
    const accountHasInsured = useSelector(easyRefillAccountHasInsuranceSelector);
    const autoRefillFlags = useSelector(autoRefillFlagsSelector);
    const easyRefillEPostPatientNumber = useSelector(easyRefillEpostPatientNumSelector);
    const autoRefillEligibleRxs = useSelector(easyRefillEligibleRxsSelector);
    const autoRefillEligibleCaregiverRxs = useSelector(easyRefillEligibleRxsCaregiverSelector);
    const easyRefillRxsToRefill = useSelector(easyRefillRxsToRefillSelector); // This selector should be used as the cart simulation.
    const easyRefillIsCaregiver = useSelector(easyRefillIsCaregiverSelector);
    const easyRefillFamilyDependents = useSelector(easyRefillFamilyDependentsSelector);
    const easyRefill = useSelector(easyRefillSelector);
    const easyRefillCartItems = useSelector(easyRefillCartItemsSelector);
    const easyRefillIsBusy = useSelector(autoRefillToggleBusySelector);
    const easyRefillCartLoading = useSelector(easyRefillLoadingCartSelector);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const isCaliforniaUser = useSelector(easyRefillAccountIsCaliforniaUserSelector);
    const isAutoRefillToggleBusy = useSelector(easyRefillToggleBusySelector);
    const removeCartBtnDisabled = useSelector(easyRefillRemoveCartBtnDisabled);
    const isRxAvailable = useSelector(easyRefillFamilyRxSelector);
    const isExpired = useSelector(easyRefillExpiredSelector);
    const accountPlans = useSelector(easyRefillPlansSelector);
    const cartsObject = useSelector(cartSelector);
    const planAlias = useSelector(easyRefillAcCodeSelector);
    const orderShippingAddress = useSelector(easyRefillOrderAddressSelector);
    const patientPaymentCards = useSelector(easyRefillPaymentCardsSelector);
    const patientAddressses = useSelector(easyRefillAddressesSelector);
    const patientDefaultAddress = useSelector(easyRefillPatientAddressSelector);
    const healthConditions = useEasyRefillHealthConditions();

    const currentZipCode = orderShippingAddress?.zipcode || patientDefaultAddress?.zipcode || '';

    const isMembership: boolean = planAlias === 'BRD02';

    const shouldDisplayAutoRefillInfo = useMemo(() => {
        if (easyRefillIsCaregiver) {
            return easyRefillFamilyDependents.some((dependent) =>
                dependent.autoRefillFlags?.some(
                    (flag) => flag.planAutoRefillEligible && activeDependentTab === dependent.epostPatientNum
                )
            );
        } else {
            return easyRefill.autoRefillFlags?.some((flag) => flag.planAutoRefillEligible);
        }
    }, [easyRefill, easyRefillFamilyDependents, easyRefillIsCaregiver, activeDependentTab]);

    const handleShowErrorModal = useCallback(
        (errorText: string) => {
            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                    ),
                    bodyContent: <EasyRefillErrorModal translation={t} errorMessage={errorText} />,
                    ctas: [
                        {
                            label: t('modals.easyRefillFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'EasyRefillNotVerified'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    // DRX-3165: This useEffect handles post-order actions in Easy Refill.
    // It verifies if an order has been placed by checking a specific cookie.
    // If an order exists, it dispatches actions to fetch patient data and cart details,
    // then removes the order placement cookie to prevent redundant triggers.
    useEffect(() => {
        const hasPlacedAnOrder = storageHelper.cookies.getHasOrderEasyRefillPlaced();

        if (hasPlacedAnOrder) {
            dispatch(easyRefillGetPatientDataRoutine.trigger({}));
            dispatch(easyRefillGetCartRoutine.trigger());
            storageHelper.cookies.removeHasOrderEasyRefillPlaced();
        }
    }, [dispatch]);

    /***********************************  Validate Error pages Scenarios   ***************************/

    // DRX-2420: This useEffect validates the availability of prescriptions
    // for both caregiver and non-caregiver accounts, while also waiting for
    // the prescriptions to be loaded.
    useEffect(() => {
        if (easyRefillRxsLoaded) {
            if (easyRefillIsCaregiver && isRxAvailable === false) {
                navigate('/no-refills-available');
            } else if (!easyRefillIsCaregiver && easyRefillRxs.length === 0) {
                navigate('/no-refills-available');
            }
        }
    }, [easyRefillIsCaregiver, isRxAvailable, easyRefillRxs, easyRefillRxsLoaded]);

    useEffect(() => {
        if (isExpired || !easyRefillBearerToken) {
            navigate('/link-expired?flow=easy-refill');
        }
    }, [easyRefillBearerToken, isExpired]);

    /***************************************************************************************************/

    /************************************  Patient Data Api Call   ************************************/

    // DRX-2425: Due to various distinct requirements causing a potential performance loss
    // when the flow is invoked multiple times, this useEffect is placed at the top
    // to fetch the information once.

    useEffect(() => {
        if (easyRefillRxsLoaded) return;

        // Get Patient Data
        // do not call the patient data if the rxResults already populated

        if (easyRefillIsCaregiver) setActiveDependentTab(easyRefillEPostPatientNumber);
        dispatch(
            easyRefillGetPatientDataRoutine.trigger({
                onFailure: (error: Pick<CreateOrUpdateResponse, 'messageText'>) => {
                    handleShowErrorModal(
                        t('pages.easyRefill.prescriptions.errorRetrievingData', {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })
                    );
                }
            })
        );

        if (patientAddressses.length == 0) dispatch(easyRefillGetPatientAddressesRoutine.trigger());
        if (patientPaymentCards.length == 0) dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
        if (!healthConditions.ePostPatientNum) dispatch(easyRefillFetchHealthConditionsRoutine.trigger());
        // Start the Cart process by getting all the items from the Easy Caregiver Cart.
        dispatch(easyRefillGetCartRoutine.trigger());

        if (easyRefillEPostPatientNumber) setActiveDependentTab(easyRefillEPostPatientNumber);
    }, []);

    // This function checks whether the account has dependents, ensures a match for each one,
    // and retrieves the epostPatientNum to make a specific API call.
    const familyMembers = useMemo(() => {
        if (easyRefillFamilyDependents.length > 0) {
            const familyMembers = mapEasyRefillFamilyMembers(easyRefillFamilyDependents, true);
            return familyMembers;
        } else {
            return null;
        }
    }, [easyRefillFamilyDependents]);

    // DRX-2425: Because of variations in response objects for patient data, we need to dispatch API calls differently.
    // In caregiver accounts, the call must be made during the mapping of family members through the familyMembers validation.
    // However, in normal accounts, we directly call the API with the EpostpatientNum for the patient in the flow.
    useEffect(() => {
        if (!accountPlans?.length && familyMembers) {
            dispatch(easyRefillGetPatientPlansRoutine({ familyMembers: familyMembers }));
        } else if (!easyRefillIsCaregiver && !accountPlans?.length && easyRefillEPostPatientNumber) {
            dispatch(easyRefillGetPatientPlansRoutine({ familyMembers: [easyRefillEPostPatientNumber] }));
        }
    }, [
        accountPlans,
        dispatch,
        easyRefillEPostPatientNumber,
        easyRefillIsCaregiver,
        easyRefillRxsLoaded,
        familyMembers
    ]);

    /***************************************************************************************************/

    useEffect(() => {
        const easyRefillSorted = [...easyRefillRxs]
            .sort((a, b) => {
                return easyRefillRxsToRefill.includes(a.rxNumber) ? -1 : 1;
            })
            .map((prescription) => {
                const isInCart = (easyRefillCartItems || []).find((rx) => rx.rxNumber === prescription.rxNumber);
                const prescriptionOldData = prescription?.prescriptionOldData ? prescription?.prescriptionOldData : {};

                if (isInCart) {
                    return { ...prescription, inOrderCart: true, prescriptionOldData: { ...prescription } };
                } else {
                    return { ...prescription, ...prescriptionOldData, inOrderCart: false };
                }
            });

        setOrderedEasyRefillRxs(easyRefillSorted);
    }, [easyRefillRxs, easyRefillRxsToRefill]);

    useEffect(() => {
        if (!easyRefillFamilyDependents.length || !activeDependentTab) return;

        const dependent = easyRefillFamilyDependents.find((item) => item.epostPatientNum === activeDependentTab);
        if (!dependent) return;

        const prescriptionsInCart = dependent.rxResults.map((prescription) => {
            const isInCart = (easyRefillCartItems || []).find((rx) => rx.rxNumber === prescription.rxNumber);
            const prescriptionOldData = prescription?.prescriptionOldData ? prescription?.prescriptionOldData : {};

            if (isInCart) {
                return { ...prescription, inOrderCart: true, prescriptionOldData: { ...prescription } };
            } else {
                return { ...prescription, ...prescriptionOldData, inOrderCart: false };
            }
        });

        setOrderedEasyRefillRxs(prescriptionsInCart);
    }, [easyRefillFamilyDependents, activeDependentTab]);

    // Each time that the Cart Object is updated, instead of requesting a new
    // list of prescriptions for the API and wait its result to sort the items,
    // we get the list of prescriptions stored inside the status and change the
    // inOrderCart manually to be possible to update the card type and its order.
    //
    // Ticket implementation: DRX-1898
    useEffect(() => {
        if (easyRefillCartItems && Array.isArray(easyRefillCartItems)) {
            let prescriptions: any = [];
            if (easyRefillFamilyDependents.length && activeDependentTab) {
                prescriptions =
                    easyRefillFamilyDependents.find((item) => item.epostPatientNum === activeDependentTab)?.rxResults ||
                    orderedEasyRefillRxs;
            } else {
                prescriptions = easyRefillRxs;
            }

            const prescriptionsInCart = (prescriptions || []).map((prescription: any) => {
                const isInCart = easyRefillCartItems.find((rx) => rx.rxNumber === prescription.rxNumber);

                // The prescription old data is used to return the prescription to its old order this is
                // defined based on more than one information combined. Setting the prescription old data
                // makes possible to give back the old position for this prescription after being remove from
                // the cart.
                //
                // This does not work for items that were already loaded at the cart during the page load, because
                // we will discover the prescription real status just after receiving the data from API.
                const prescriptionOldData = prescription?.prescriptionOldData ? prescription?.prescriptionOldData : {};

                if (isInCart) {
                    return { ...prescription, inOrderCart: true, prescriptionOldData: { ...prescription } };
                } else {
                    return { ...prescription, ...prescriptionOldData, inOrderCart: false };
                }
            });
            setOrderedEasyRefillRxs(prescriptionsInCart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [easyRefillCartItems]);

    useEffect(() => {
        const easyRefillsElegibleRxs = easyRefillIsCaregiver ? autoRefillEligibleCaregiverRxs : autoRefillEligibleRxs;
        // Get discount drug prices after the profile and all prescriptions are loaded.
        if (easyRefillsElegibleRxs.length > 0) {
            let birdiPricePrescriptions: any[] = [];
            // DRX-583 - get prices for Insured users to pre-fetch for cart. We only need to get
            // prices for items in cart.
            if (accountHasInsured && ALLOW_INSURED_BIRDI_PRICE) {
                // DRX-996 - Since we have to show a different message when an item is added to the cart,
                // we need to get all Rxs that are in the cart, or could be added to the cart.
                birdiPricePrescriptions = easyRefillsElegibleRxs.filter(function (rx) {
                    return (
                        rx.inOrderCart === true ||
                        rx.webEligibilityStatus === 'ELIGIBLE' ||
                        rx.webEligibilityStatus === 'AUTH_REQ'
                    );
                });
            } else {
                // No need to get "pending" prescriptions for Birdi price users
                if (!accountHasInsured) {
                    birdiPricePrescriptions = easyRefillsElegibleRxs.filter(function (rx) {
                        return !!(rx.dispensedProductNumber || rx.writtenProductNumber);
                    });
                }
            }
            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: birdiPricePrescriptions,
                    forceBirdiInsurance: !!(accountHasInsured && ALLOW_INSURED_BIRDI_PRICE),
                    location: 'Cart'
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoRefillEligibleRxs.length, autoRefillEligibleCaregiverRxs.length, orderShippingAddress]);

    const handleAddToCartClick = (rxNumber: string, epostPatientNum: string) => {
        setIsRxBusy(true);
        // Convert variables to strings if they are not already strings
        rxNumber = typeof rxNumber !== 'string' ? String(rxNumber) : rxNumber;
        epostPatientNum = typeof epostPatientNum !== 'string' ? String(epostPatientNum) : epostPatientNum;

        // Check if the item is already in the cart
        const rxInCart = easyRefillCartItems?.some((cartItem) => cartItem.rxNumber === rxNumber);
        if (!rxInCart) {
            dispatch(
                easyRefillStartOrderRoutine.trigger({
                    rxNumber: rxNumber,
                    epostPatientNum: epostPatientNum,
                    onSuccess: () => {
                        setIsRxBusy(false);
                        const extendedCart = processEasyRefillCart(
                            cartsObject,
                            isMembership || accountHasInsured,
                            easyRefillRxs,
                            drugDiscountPrices,
                            accountPlans,
                            currentZipCode
                        );
                        // DRX-2008: Validate if this helper could be usable as the authenticated Cart.
                        TrackCheckoutStep({
                            stepName: 'add',
                            step: '1',
                            carts: extendedCart,
                            prescriptions: easyRefillRxs,
                            t: t,
                            shippingCost: '0',
                            accountHasInsurance: accountHasInsured
                        });
                        dispatch(
                            easyRefillGetCartRoutine.trigger({
                                onSuccess: () => {
                                    dispatch(closeModal({}));
                                }
                            })
                        );
                        dispatch(easyRefillGetPatientDataRoutine.trigger({ withoutLoading: true }));
                    },
                    onFailure: () => {
                        setIsRxBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'alert'}
                                        title={t(`pages.medicineCabinet.messages.callbacks.error`)}
                                        body={t(`pages.medicineCabinet.messages.callbacks.errorMessage`)}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        }
    };

    const handleAutoRefillToggle = useCallback(
        debounce((autoRefillRxs: EasyRefillRxResult[], autoRefillEnabled: boolean, isRenew?: boolean) => {
            autoRefillRxs.forEach(async (rx) => {
                dispatch(
                    easyRefillToggleAutoRefillRxStatusRoutine.trigger({
                        rxsToToggle: {
                            rxSeqNum: rx.rxSeqNum,
                            newStatus: autoRefillEnabled,
                            rxNumber: rx.rxNumber,
                            isRenew: isRenew
                        },
                        onSuccess: () => {
                            dispatch(easyRefillGetCartRoutine.trigger());
                            dispatch(
                                easyRefillGetPatientDataRoutine.trigger({
                                    withoutLoading: true
                                })
                            );
                            dispatch(closeModal({}));
                        }
                    })
                );
            });
        }, 500),
        [dispatch]
    );

    const handleShowTCModal = useCallback(
        (rxNumbers: EasyRefillRxResult[], autoRefillEnabled: boolean, isRenew?: boolean) => {
            dispatch(
                openModal({
                    onClose: () => {
                        dispatch(closeModal({}));
                    },
                    ctas: [
                        {
                            label: t('modals.autoRefillTC.confirm'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(setBusyModal(true));
                                handleAutoRefillToggle(rxNumbers, autoRefillEnabled, isRenew);
                            },
                            async: true,
                            dataGALocation: 'UnauthenticatedAutoRefillTCConfirm'
                        },
                        {
                            label: t('modals.autoRefillTC.cancel'),
                            variant: 'text',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'UnauthenticatedAutoRefillTCCancel',
                            className: 'p-4'
                        }
                    ],
                    bodyContent: <AutoRefillTermsAndConditionsModal t={t} />,
                    showClose: true
                })
            );
        },
        [dispatch, handleAutoRefillToggle, t]
    );

    const handleShowAutoRefillToggleModal = useCallback(
        (rxNumbers: EasyRefillRxResult[], autoRefillEnabled: boolean, isRenew?: boolean) => {
            const isAnyRxHasAutoRefill = easyRefill.autoRefillFlags?.some((rx) => rx.autoRefillEnabled);
            const showAutoRefillTermsAndConditions = validateAutoRefillTermsAndConditionsModalDisplay(
                autoRefillEnabled,
                isAnyRxHasAutoRefill,
                // TODO: Remove hardcoded false in DRX-3762 when we have the BE property of auto refill flag
                false,
                isCaliforniaUser
            );

            if (showAutoRefillTermsAndConditions) {
                handleShowTCModal(rxNumbers, autoRefillEnabled, isRenew);
            } else {
                handleAutoRefillToggle(rxNumbers, autoRefillEnabled);
            }
        },
        [handleAutoRefillToggle, handleShowTCModal, easyRefill.autoRefillFlags, isCaliforniaUser]
    );

    const getAutoRefillProperty = useCallback(
        (rxNumber: string, activeDependent: string | null = null, property: string) => {
            const findRx = (autoRefillArr: any) => {
                if (!autoRefillArr) return null;
                return autoRefillArr.find((rx: AutoRefillFlag) => rx.rxNumber === rxNumber);
            };

            if (easyRefillIsCaregiver && activeDependent) {
                const selectedEligibleRxs = easyRefillFamilyDependents.find(
                    (dependent) => dependent.epostPatientNum === activeDependent
                );
                const foundRx = findRx(selectedEligibleRxs?.autoRefillFlags);

                if (!foundRx) return false;
                return foundRx[property];
            }
            const foundRx = findRx(autoRefillFlags);
            if (!foundRx) return false;
            return foundRx[property];
        },
        [autoRefillFlags, easyRefillIsCaregiver, easyRefillFamilyDependents]
    );

    const rxAutoRefillEnabled = useCallback(
        (rxNumber: string, activeDependent: string | null = null) =>
            getAutoRefillProperty(rxNumber, activeDependent, 'autoRefillEnabled'),
        [getAutoRefillProperty]
    );

    const rxAutoRefillEligible = useCallback(
        (rxNumber: string, activeDependent: string | null = null) =>
            getAutoRefillProperty(rxNumber, activeDependent, 'autoRefillEligible'),
        [getAutoRefillProperty]
    );

    const handleTabItemClick = (tab = 'all', dependent: string | null) => {
        setActiveDependentTab(dependent);
    };

    const formatFamilyMembersData = (data: EasyRefillPatientDataCaregiver[]) => {
        return (
            data.length > 0 &&
            data.map((item, key) => ({
                ePostPatientNum: item.epostPatientNum,
                familyMemberName: lowercaseAndCapitalize(`${item.firstName || ''} ${item.lastName || ''}`),
                id: key
            }))
        );
    };

    const renderFamilyPrescriptions = useCallback(
        (prescriptions: EasyRefillRxResult[] | null, dependent: string | null) => {
            return (
                <>
                    {prescriptions?.length === 0 ? (
                        <EmptyState
                            variant="has-border"
                            icon={NoRefillsIcon}
                            title={t('components.emptyState.noRefills.title')}
                            description={t('components.emptyState.noRefills.description')}
                        />
                    ) : (
                        prescriptions &&
                        prescriptions.map((prescription, index) => (
                            <Col
                                className="p-0 mb-4 flex-fill auto-refill-rx-card"
                                key={`auto-refill-rx-card-${index}`}
                            >
                                <div className="d-md-none">
                                    <EasyRefillPrescriptionCardV2Mobile
                                        {...easyRefillPrescriptionPayloadToProps(
                                            prescription,
                                            t,
                                            accountHasInsured,
                                            easyRefillRxsToRefill.includes(prescription.rxNumber),
                                            () => {
                                                handleAddToCartClick(
                                                    prescription.rxNumber,
                                                    prescription.epostPatientNum
                                                );
                                            }
                                        )}
                                        autoRefillEligibleForRefill={rxAutoRefillEligible(
                                            prescription.rxNumber,
                                            dependent
                                        )}
                                        autoRefillEnabledForRx={rxAutoRefillEnabled(prescription.rxNumber, dependent)}
                                        autoRefillOnChange={handleShowAutoRefillToggleModal}
                                        isCaliforniaUser={isCaliforniaUser}
                                        autoRefillBusy={easyRefillIsBusy}
                                        autorefillChange={
                                            easyRefillCartLoading || isAutoRefillToggleBusy || removeCartBtnDisabled
                                        }
                                        isRxBusy={isRxBusy}
                                        zipCode={currentZipCode}
                                    />
                                </div>
                                <div className="d-none d-md-flex">
                                    <EasyRefillPrescriptionCardV2Desktop
                                        {...easyRefillPrescriptionPayloadToProps(
                                            prescription,
                                            t,
                                            accountHasInsured,
                                            easyRefillRxsToRefill.includes(prescription.rxNumber),
                                            () => {
                                                handleAddToCartClick(
                                                    prescription.rxNumber,
                                                    prescription.epostPatientNum
                                                );
                                            }
                                        )}
                                        autoRefillEligibleForRefill={rxAutoRefillEligible(
                                            prescription.rxNumber,
                                            dependent
                                        )}
                                        autoRefillEnabledForRx={rxAutoRefillEnabled(prescription.rxNumber, dependent)}
                                        autoRefillOnChange={handleShowAutoRefillToggleModal}
                                        isCaliforniaUser={isCaliforniaUser}
                                        autoRefillBusy={easyRefillIsBusy}
                                        autorefillChange={easyRefillCartLoading || isAutoRefillToggleBusy}
                                        isRxBusy={isRxBusy}
                                        zipCode={currentZipCode}
                                    />
                                </div>
                            </Col>
                        ))
                    )}
                </>
            );
        },
        [
            easyRefillRxsToRefill,
            easyRefillIsBusy,
            easyRefillCartLoading,
            isAutoRefillToggleBusy,
            removeCartBtnDisabled,
            isRxBusy
        ]
    );

    return (
        <MarketingPageLayout
            headerImage={blueSkyBackground}
            headerImageClassName="profile-background"
            metaData={{ nodeTitle: 'Easy Refill' }}
            suppressApplicationPage={true}
        >
            <PageSection>
                <Container fluid className="easy-refill-prescriptions">
                    <InternalHeader
                        title={t('pages.easyRefill.prescriptions.headlineText')}
                        eyebrowText={t('pages.easyRefill.prescriptions.eyebrowText')}
                        sectionIndex={0}
                        body={
                            easyRefillLoadingRxs ? (
                                <Spinner isVisible={easyRefillLoadingRxs} t={t} />
                            ) : (
                                <>
                                    <p>
                                        {t('pages.easyRefill.prescriptions.greeting', {
                                            firstName: capitalizeFirstLetter(firstName.toLocaleLowerCase())
                                        })}{' '}
                                    </p>
                                </>
                            )
                        }
                    />

                    <div className="easy-refill-prescriptions-content">
                        <div className="easy-refill-prescriptions-content-prescriptions">
                            {ENABLE_AUTO_REFILL && shouldDisplayAutoRefillInfo && !easyRefillLoadingRxs && (
                                <AutoRefillBanner />
                            )}
                            {/* Select all Rxs normal patients */}

                            {/* DRX-1760: This will we a future implementation */}

                            {/* {!easyRefillLoadingRxs && !easyRefillIsCaregiver && (
                                    <Row>
                                        <Checkbox
                                            id="select-all-checkbox"
                                            label={t('pages.easyRefill.prescriptions.refillAllPrescriptions')}
                                            onClick={() => {}}
                                            onCheckChanged={() => {}}
                                        />
                                    </Row>
                                )} */}
                            {!easyRefillLoadingRxs && easyRefillIsCaregiver && (
                                <>
                                    <Col xs={12} md={12} lg={12}>
                                        <Row>
                                            <Col className="easy-refill-prescription-tabs" xs={12} md={12} lg={12}>
                                                {easyRefillFamilyDependents.length > 0 && (
                                                    <PrescriptionTabs
                                                        activeTab={'all'}
                                                        activeDependentTab={
                                                            activeDependentTab || easyRefillEPostPatientNumber
                                                        }
                                                        isPrescriptionsAvailable={false}
                                                        onTabItemChange={handleTabItemClick}
                                                        dependents={
                                                            formatFamilyMembersData(easyRefillFamilyDependents) || []
                                                        }
                                                        myEpostPatientNum={easyRefillEPostPatientNumber}
                                                        hasFilterTabs={false}
                                                    />
                                                )}
                                            </Col>

                                            {/* Select all Rxs for Caregivers */}

                                            {/* DRX-1760: This will we a future implementation */}

                                            {/* {!easyRefillLoadingRxs && easyRefillIsCaregiver && (
                                                    <Row className="w-100 easy-refill-select-all">
                                                        <Checkbox
                                                            id="select-all-checkbox"
                                                            label={t(
                                                                'pages.easyRefill.prescriptions.refillAllPrescriptions'
                                                            )}
                                                            onClick={() => {}}
                                                            onCheckChanged={() => {}}
                                                        />
                                                    </Row>
                                                )} */}

                                            {!easyRefillLoadingRxs &&
                                                !isExpired &&
                                                renderFamilyPrescriptions(orderedEasyRefillRxs, activeDependentTab)}
                                        </Row>
                                    </Col>
                                </>
                            )}
                            {!easyRefillLoadingRxs && !easyRefillIsCaregiver && !isExpired && (
                                <>
                                    {orderedEasyRefillRxs && orderedEasyRefillRxs.length > 0 ? (
                                        <>
                                            {orderedEasyRefillRxs.map((prescription, index) => (
                                                <Col
                                                    className="p-0 mb-4 flex-fill auto-refill-rx-card"
                                                    key={`auto-refill-rx-card-${index}`}
                                                >
                                                    <div className="d-md-none">
                                                        <EasyRefillPrescriptionCardV2Mobile
                                                            {...easyRefillPrescriptionPayloadToProps(
                                                                prescription,
                                                                t,
                                                                accountHasInsured,
                                                                easyRefillRxsToRefill.includes(prescription.rxNumber),
                                                                () => {
                                                                    handleAddToCartClick(
                                                                        prescription.rxNumber,
                                                                        prescription.epostPatientNum
                                                                    );
                                                                }
                                                            )}
                                                            autoRefillEligibleForRefill={rxAutoRefillEligible(
                                                                prescription.rxNumber
                                                            )}
                                                            autoRefillEnabledForRx={rxAutoRefillEnabled(
                                                                prescription.rxNumber
                                                            )}
                                                            autoRefillOnChange={handleShowAutoRefillToggleModal}
                                                            autoRefillBusy={easyRefillIsBusy}
                                                            autorefillChange={
                                                                easyRefillCartLoading || isAutoRefillToggleBusy
                                                            }
                                                            isCaliforniaUser={isCaliforniaUser}
                                                            isRxBusy={isRxBusy}
                                                            zipCode={currentZipCode}
                                                        />
                                                    </div>
                                                    <div className="d-none d-md-flex">
                                                        <EasyRefillPrescriptionCardV2Desktop
                                                            {...easyRefillPrescriptionPayloadToProps(
                                                                prescription,
                                                                t,
                                                                accountHasInsured,
                                                                easyRefillRxsToRefill.includes(prescription.rxNumber),
                                                                () => {
                                                                    handleAddToCartClick(
                                                                        prescription.rxNumber,
                                                                        prescription.epostPatientNum
                                                                    );
                                                                }
                                                            )}
                                                            autoRefillEligibleForRefill={rxAutoRefillEligible(
                                                                prescription.rxNumber
                                                            )}
                                                            autoRefillEnabledForRx={rxAutoRefillEnabled(
                                                                prescription.rxNumber
                                                            )}
                                                            autoRefillOnChange={handleShowAutoRefillToggleModal}
                                                            autoRefillBusy={easyRefillIsBusy}
                                                            autorefillChange={
                                                                easyRefillCartLoading || isAutoRefillToggleBusy
                                                            }
                                                            isCaliforniaUser={isCaliforniaUser}
                                                            isRxBusy={isRxBusy}
                                                            zipCode={currentZipCode}
                                                        />
                                                    </div>
                                                </Col>
                                            ))}
                                        </>
                                    ) : (
                                        <span className="text-dark">
                                            {t('pages.autoRefill.prescriptions.noResultsFound')}
                                        </span>
                                    )}
                                </>
                            )}
                        </div>
                        {!easyRefillLoadingRxs && <RefillDetailsCart />}
                    </div>
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default withUnauthenticatedSessionExpiration(EasyRefillPrescriptions, 'easy-refill');

export const query = graphql`
    query GetEasyRefillPrescriptionLanguageAndImageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background-02.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
