import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import { ButtonProps } from 'ui-kit/button/button.props';
import BirdiPriceIcon from 'ui-kit/icons/birdi-price/birdi-price-icon';
import PlanBadge from 'ui-kit/icons/plan-badge/plan-badge';

import EmptyAlertBox from 'components/medicine-cabinet-cart/empty-alert-box/empty-alert-box';

// Order, Prescriptions & Cart
import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';
import {
    easyRefillEligibleRxsCaregiverSelector,
    easyRefillEligibleRxsSelector,
    easyRefillFamilyPlansMapSelector,
    easyRefillIsCaregiverSelector,
    easyRefillPlanAliasSelector,
    easyRefillRemoveCartBtnDisabled
} from 'state/easy-refill/easy-refill.selectors';

import { formatPrice } from 'schema/price.schema';

import { EasyRefillRxResult, ExtendedEasyRefillRxs } from 'types/easy-refill';

// Utils
import { hasAdjudicatedPriceInEasyRefill } from 'util/easy-refill';
import { noop } from 'util/function';
import { getPhoneNumber } from 'util/globalVariables';
import { safeParseFloat } from 'util/number';

// UI Kit & Components
import AutoRefillBadge from '../auto-refill-badge/auto-refill-badge';
import './prescription-item.style.scss';

export interface PrescriptionItemProps {
    isLoading?: boolean;
    isCaregiver?: boolean;
    patientName?: string;
    onRemovePrescriptionClick?: ButtonProps['onClick'];
    prescription?: ExtendedEasyRefillRxs;
    prescriptionDetails?: EasyRefillRxResult;
    isInsuranceAccount?: boolean;
    discountPrice?: DrugWithDiscountPrice;
}

const PrescriptionItem: React.FC<PrescriptionItemProps> = (props) => {
    const { onRemovePrescriptionClick = noop, prescriptionDetails, prescription } = props;

    const { t } = useTranslation();

    const removeCartBtnDisabled = useSelector(easyRefillRemoveCartBtnDisabled);
    const autoRefillEligibleCaregiverRxs = useSelector(easyRefillEligibleRxsCaregiverSelector);
    const autoRefillEligibleRxs = useSelector(easyRefillEligibleRxsSelector);
    const easyRefillIsCaregiver = useSelector(easyRefillIsCaregiverSelector);
    const familyPlansMap: Record<string, string> = useSelector(easyRefillFamilyPlansMapSelector);
    const userPlanAlias: string = useSelector(easyRefillPlanAliasSelector);
    const epostPatientNum: string = prescriptionDetails?.epostPatientNum || '';

    const isMembershipPlanAlias: boolean =
        Object.keys(familyPlansMap).length > 0
            ? familyPlansMap[epostPatientNum] === 'BRD02'
            : userPlanAlias === 'BRD02';

    const shouldDisplayAutoRefill = () => {
        const rxNum = prescription?.prescriptionDetail?.rxNumber;

        if (easyRefillIsCaregiver) {
            const foundRx = autoRefillEligibleCaregiverRxs.find((rx) => rx.rxNumber == rxNum);
            return foundRx && foundRx?.autoFillStatus === 'Y';
        }

        const foundRx = autoRefillEligibleRxs.find((rx) => rx.rxNumber == rxNum);
        return foundRx && foundRx?.autoFillStatus === 'Y';
    };

    const formatBirdiSavings = React.useCallback(
        (item: ExtendedEasyRefillRxs) => {
            if (item) {
                const normalPrice = safeParseFloat(item.awpPrice);
                const birdiPrice = safeParseFloat(item.birdiPrice);

                if (birdiPrice < normalPrice) {
                    const savingsAsDollar = normalPrice - birdiPrice;
                    return t('components.medicineCabinetCart.prescriptionInfo.save', {
                        save: formatPrice(savingsAsDollar)
                    });
                }
            } else {
                return '';
            }
        },
        [t]
    );

    const validateDiscountVisibility = React.useCallback((item: ExtendedEasyRefillRxs) => {
        if (!item) {
            return undefined;
        }

        const normalPrice = Number(item.awpPrice);
        const birdiPrice = Number(item.birdiPrice);

        return birdiPrice < normalPrice ? normalPrice - birdiPrice : undefined;
    }, []);

    return (
        <div className="pb-3">
            {prescription && prescriptionDetails && (
                <>
                    <section className="prescription-item-wrapper">
                        {props.isCaregiver ? (
                            <h3 className="prescription-item-patient-name font-weight-bolder">{props.patientName}</h3>
                        ) : (
                            <></>
                        )}
                        {/* First Section */}
                        <div className="prescription-item">
                            {/* Drug price */}
                            <div className="prescription-item-info text-ebony-clay">
                                {/* Product Name */}
                                <div className="prescription-item-info__name">
                                    {prescriptionDetails?.dispensedProductName}
                                    {/* Plan Badge */}
                                    {/* One more condition should be added, related if the user is 'On Demand', or 'Birdi Select' */}
                                    {isMembershipPlanAlias && prescriptionDetails?.isBirdiSelect && (
                                        <PlanBadge variant="birdi-select-blue" />
                                    )}
                                </div>

                                {/* When the price is not adjudicted */}
                                {(!hasAdjudicatedPriceInEasyRefill(prescription, prescriptionDetails) ||
                                    isMembershipPlanAlias) &&
                                !prescription?.hasKnownPrice &&
                                prescriptionDetails?.dispensedProductName ? (
                                    <sup>*</sup>
                                ) : (
                                    ''
                                )}
                                {/* Prescription number */}
                                <div className="prescription-item-rx mt-2">
                                    {t('components.medicineCabinetCart.prescriptionInfo.rx', {
                                        number: prescription?.rxNumber
                                    })}
                                </div>
                                {/* Qunatities */}
                                <div className="d-flex">
                                    <div className="prescription-item-quantities d-flex justify-content-between align-items-center">
                                        {prescriptionDetails?.fillQuantity && (
                                            <div>
                                                {t('pages.cart.quantity', {
                                                    fillQuantity: prescriptionDetails.fillQuantity
                                                })}
                                            </div>
                                        )}

                                        {(prescriptionDetails?.dispensedProductStrength ||
                                            prescriptionDetails?.dispensedProductUnitOfMeasure) && (
                                            <span className="divider mx-1 text-slate prescription-item-rx">|</span>
                                        )}

                                        {(prescriptionDetails?.dispensedProductStrength ||
                                            prescriptionDetails?.dispensedProductUnitOfMeasure) && (
                                            <div>
                                                {prescriptionDetails.dispensedProductStrength}
                                                {prescriptionDetails.dispensedProductUnitOfMeasure}
                                            </div>
                                        )}

                                        {prescriptionDetails?.dispensedProductDosageForm && (
                                            <span className="divider mx-1 text-slate prescription-item-rx">|</span>
                                        )}

                                        {prescriptionDetails?.dispensedProductDosageForm && (
                                            <div>{prescriptionDetails.dispensedProductDosageForm}</div>
                                        )}
                                    </div>
                                </div>
                                {shouldDisplayAutoRefill() && prescriptionDetails?.autoRefillEnabled ? (
                                    <div className="mt-2">
                                        <AutoRefillBadge
                                            text={t('components.medicineCabinetCart.prescriptionInfo.autoRefill')}
                                        />
                                    </div>
                                ) : null}
                            </div>

                            <div className="left-n1 ml-lg-auto text-right prescription-item-price">
                                {(hasAdjudicatedPriceInEasyRefill(prescription, prescriptionDetails) ||
                                    isMembershipPlanAlias) &&
                                prescription?.hasKnownPrice ? (
                                    <>
                                        {validateDiscountVisibility(prescription) && (
                                            <div className="prescription-item-price-line">${prescription.awpPrice}</div>
                                        )}
                                        <div className="prescription-item-price-total">
                                            {formatPrice(
                                                prescription.isUsingBirdiPrice
                                                    ? prescription.birdiPrice
                                                    : prescription.patientCopay
                                            )}
                                        </div>
                                        {/* Est.Copay - just for insurance patients */}
                                        {prescription &&
                                            prescription.insurancePayment !== null &&
                                            prescription.insurancePayment > 0 && (
                                                <div className="prescription-item-price-copay">
                                                    {t('components.medicineCabinetCart.prescriptionInfo.copay')}
                                                </div>
                                            )}
                                    </>
                                ) : (
                                    <div className="prescription-item-no-price">
                                        {' '}
                                        {t('components.medicineCabinetCart.prescriptionInfo.noPrice')}{' '}
                                    </div>
                                )}

                                {(hasAdjudicatedPriceInEasyRefill(prescription, prescriptionDetails) ||
                                    isMembershipPlanAlias) &&
                                    prescription?.hasKnownPrice && (
                                        <div>
                                            {/* Birdi price */}
                                            <div className="d-flex justify-content-end prescription-item-birdi-price">
                                                <BirdiPriceIcon />
                                                <div>
                                                    {t('components.medicineCabinetCart.prescriptionInfo.birdiPrice')}
                                                </div>
                                            </div>

                                            {/* Disccount price - Just for insurance patients */}
                                            {validateDiscountVisibility(prescription) && (
                                                <div className="d-flex text-right mt-1 prescription-item-saving justify-content-end">
                                                    {formatBirdiSavings(prescription)}
                                                </div>
                                            )}
                                        </div>
                                    )}

                                {/* Remove Button */}
                                {!removeCartBtnDisabled && (
                                    <Button
                                        type="button"
                                        variant="text"
                                        className="px-0 pt-0 no-min-width text-cerulean left-n1 ml-lg-auto mt-2 font-weight-bold text-right"
                                        label={t('shipping.removeAddress')}
                                        onClick={onRemovePrescriptionClick}
                                        dataGAFormName="addMorePrescriptions"
                                    />
                                )}
                            </div>
                        </div>
                    </section>

                    {prescription?.showDisclaimer && prescription?.disclaimerTranslationKey && (
                        <Row className="d-flex justify-content-between pt-4">
                            <EmptyAlertBox
                                text={t(
                                    prescription?.disclaimerTranslationKey,

                                    {
                                        phoneNumber: getPhoneNumber({})
                                    }
                                )}
                            />
                        </Row>
                    )}
                </>
            )}
        </div>
    );
};

export default PrescriptionItem;
