import { useTranslation } from 'react-i18next';

import Button from 'ui-kit/button/button';

import './empty-state.style.scss';

interface EmptyStateProps {
    icon: React.ComponentType;
    title: string;
    description: string;
    buttonLabel?: string;
    onNavigate?: () => void;
    dataGALocation?: string;
    variant?: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({
    icon: Icon,
    title,
    description,
    buttonLabel,
    onNavigate,
    dataGALocation,
    variant
}) => {
    const { t } = useTranslation();
    const containerClass = `empty-state ${variant ? `empty-state--${variant}` : ''}`;

    return (
        <div className={containerClass}>
            <Icon />

            <div className="empty-state__content">
                <h4>{t(title)}</h4>
                <p>{t(description)}</p>
            </div>

            {buttonLabel && onNavigate && (
                <div className="empty-state__button">
                    <Button
                        async
                        className="sm-full"
                        label={buttonLabel}
                        variant="outline-primary"
                        type="button"
                        {...(dataGALocation ? { dataGALocation } : {})}
                        onClick={onNavigate}
                    />
                </div>
            )}
        </div>
    );
};

export default EmptyState;
