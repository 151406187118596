import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI Kit
import Button from 'ui-kit/button/button';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import {
    AllergiesModalContent,
    HealthConditionsModalContent
} from 'components/easy-refill/health-conditions/easy-refill-health-profile.component';
import { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';
import EmptyAlertBox from 'components/medicine-cabinet-cart/empty-alert-box/empty-alert-box';
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

// Modal
import { HealthConditionsWarningModalContent } from 'pages/secure/profile/health-profile';

// States
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { easyRefillFetchHealthConditionsRoutine } from 'state/easy-refill/easy-refill.routines';

import { getPhoneNumber } from 'util/globalVariables';

// Hooks
import { useEasyRefillHealthConditions } from 'hooks/useEasyRefillHealthConditions';

// Styles
import './easy-refill-health-profile.style.scss';

interface HealthConditions {
    allergyChoices: (string | undefined)[];
    existingAllergies: string[];
    existingFreeformAllergies: string;
    conditionChoices: (string | undefined)[];
    existingConditions: string[];
    existingFreeformConditions: string;
    userHasNotSubmittedAllergies: boolean;
    userHasNotSubmittedConditions: boolean;
    ePostPatientNum: string;
}

export interface HealthProfileProps {
    className?: string;
    healthConditions: HealthConditions;
    includeAccordionSectionTitlePillIcon?: boolean;
    onUpdateHealthConditions: (e: HealthProfileBubbleUpdateEvent) => void;
}

const EasyRefillHealthProfile: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);
    const healthConditions = useEasyRefillHealthConditions();
    const { existingAllergies, existingConditions, userHasNotSubmittedConditions, userHasNotSubmittedAllergies } =
        healthConditions;

    // Fetch health conditions
    useEffect(() => {
        if (!healthConditions.ePostPatientNum) dispatch(easyRefillFetchHealthConditionsRoutine.trigger());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdateConditionsClick = ({ action, update }: HealthProfileBubbleUpdateEvent) => {
        dispatch(
            openModal({
                showClose: false,
                type: 'primary',
                size: 'lg',
                className: 'modal-health-profile-warning',
                headerContent: <BirdiModalHeaderDanger headerText={t('modals.healthConditions.title')} />,
                bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                ctas: [
                    {
                        label: t('modals.healthConditions.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                            dispatch(action(update));
                            if (columnSectionRef.current) {
                                columnSectionRef.current.handleSaveChangesButtonClick();
                            }
                        },
                        dataGALocation: 'HealthProfileWarning'
                    }
                ]
            })
        );
    };

    const handleToggleHealthConditionsClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <HealthConditionsModalContent
                        title={t('modals.prescription.addHealthCondition.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: getPhoneNumber({})
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        submitLabel={t('modals.prescription.addHealthCondition.submit')}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleToggleAllergiesClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <AllergiesModalContent
                        title={t('modals.prescription.addAllergy.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: getPhoneNumber({})
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        freeformConditionsLabel={t('components.healthConditions.labels.freeformAllergiesLabel')}
                        submitLabel={t('modals.prescription.addAllergy.submit')}
                    />
                ),
                ctas: []
            })
        );
    };

    const healthProfileContent = (
        <div className="easy-refill-health-profile">
            <div
                className="easy-refill-health-profile__intro"
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(
                        t('components.medicineCabinetCart.healthProfile.intro') + getPhoneNumber({ isEnd: true })
                    )
                }}
            />
            {(userHasNotSubmittedConditions || userHasNotSubmittedAllergies) && (
                <EmptyAlertBox text={t('components.medicineCabinetCart.healthProfile.emptyHealthConditions')} />
            )}
            <div className="easy-refill-health-profile__conditions">
                <h5 className="easy-refill-health-profile__conditions-title">
                    {t('components.medicineCabinetCart.healthProfile.healthConditions')}
                </h5>
                {!userHasNotSubmittedConditions && (
                    <div className="easy-refill-health-profile__conditions-details">
                        <HealthConditionPills conditions={existingConditions} />
                    </div>
                )}
                <div className="easy-refill-health-profile__conditions-actions">
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="column-section__button"
                        variant="text-blue-light"
                        label={t('components.medicineCabinetCart.healthProfile.addHealthConditions')}
                        type="button"
                        onClick={handleToggleHealthConditionsClick}
                    />
                </div>
            </div>
            <div className="easy-refill-health-profile__conditions">
                <h5 className="easy-refill-health-profile__conditions-title">
                    {t('components.medicineCabinetCart.healthProfile.allergies')}
                </h5>
                {!userHasNotSubmittedAllergies && (
                    <div className="easy-refill-health-profile__conditions-details">
                        <HealthConditionPills conditions={existingAllergies} />
                    </div>
                )}
                <div className="easy-refill-health-profile__conditions-actions">
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="column-section__button"
                        variant="text-blue-light"
                        label={t('components.medicineCabinetCart.healthProfile.addAllergies')}
                        type="button"
                        onClick={handleToggleAllergiesClick}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <ColumnSectionEditModeToggle
            ref={columnSectionRef}
            bodyClassName="easy-refill-health-profile__content"
            className="easy-refill-health-profile"
            editModeContent={healthProfileContent}
            headerClassName="easy-refill-health-profile__header"
            title={t('components.medicineCabinetCart.healthProfile.title')}
            chevronButton={true}
        />
    );
};
export default EasyRefillHealthProfile;
